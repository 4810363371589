/*!
 * Bootstrap (https://getbootstrap.com/)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "./bootstrap/functions";

// stylelint-disable
$primary:   #007bff;
$secondary: #6c757d;
$success:   #2fc787;
$info:      #32bfc8;
$warning:   #fdbf21;
$danger:    #ef5164;
$light:     #f8f9fa;
$dark:      #393f49;
// stylelint-enable

@import "./bootstrap/variables";

$brand:     #4f7fff !default;

// stylelint-disable
$theme-colors: map-merge(( "brand": $brand ), $theme-colors);

$breadcrumb-divider: none;
// stylelint-enable

.react-autosuggest__container {
  position: relative;
}

.logo-login {
  max-height: 80px;
  width: auto;
  margin: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fortran_dropzone {
  background-color: rgba(13, 149, 232, 0.1);
  border: 1px dashed rgba(13, 149, 232, 0.4);
  padding: 7px;
  border-radius: 4px;
  text-align: center;
}

.react-autosuggest__input {
  height: 40px;
  padding: 10px 20px;
  font-size: 13px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 39px;
  width: 280px;
  border: 1px solid #bababa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

@import "./bootstrap/mixins";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/code";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/input-group";
@import "./bootstrap/custom-forms";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/breadcrumb";
@import "./bootstrap/pagination";
@import "./bootstrap/badge";
@import "./bootstrap/jumbotron";
@import "./bootstrap/alert";
@import "./bootstrap/progress";
@import "./bootstrap/spinners";
@import "./bootstrap/toasts";
@import "./bootstrap/media";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/modal";
@import "./bootstrap/tooltip";
@import "./bootstrap/popover";
@import "./bootstrap/carousel";
@import "./bootstrap/utilities";
@import "./bootstrap/print";
