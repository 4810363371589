@import "../../../../common-assets/css/variables";
@import "../../../../common-assets/css/rootui-parts/element-popup";
@import "../../../../common-assets/css/rootui-parts/plugin-fancybox";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Fix container class on popup.
.rui-popup.container {
    @include make-container-max-widths();
}
