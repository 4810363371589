.emojionearea.rui-messenger-textarea {
    display: flex;
    align-items: center;
    width: auto;
    font-family: $font_body;
    border: 0;
    box-shadow: none;

    .emojionearea-editor {
        top: auto;
        right: 0;
        left: 4px;
        margin-right: 0;
        color: $color_text_1;

        &::before {
            margin-left: 10px;
            color: $color_grey_5;
        }
    }

    // remove background image from emoji button.
    .emojionearea-button > div,
    .emojionearea-picker .emojionearea-wrapper::after {
        text-align: center;
        // stylelint-disable-next-line
        background-image: none !important;
    }

    // correct emoji position.
    > .emojionearea-button,
    &.emojionearea-inline > .emojionearea-button {
        top: 7px;
        right: 10px;
    }
    > .emojionearea-button {
        color: $color_grey_5;
        opacity: 1;

        &:hover {
            color: inherit;
        }

        > div {
            transition: all .15s ease-in-out;
        }
    }

    // Picker
    .emojionearea-button.active + .emojionearea-picker-position-top {
        margin-top: -280px;
    }
    .emojionearea-picker {
        width: 314px;
        height: auto;
        max-height: 276px;
        margin: 0;
        border: 1px solid $color_grey_2;
        border-radius: 4px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .03);
        transition: visibility .15s ease-in-out, opacity .15s ease-in-out;

        &.emojionearea-picker-position-top {
            margin-top: -280px;

            .emojionearea-scroll-area {
                top: 55px;
                bottom: 1px;
                padding-bottom: 10px;
            }
        }
        &.emojionearea-filters-position-bottom {
            .emojionearea-scroll-area {
                top: 1px;
                bottom: 55px;
                padding-top: 10px;
            }
            .emojionearea-filters {
                bottom: 2px;
            }
        }
        &.rui-messenger-picker-complete {
            .rui-messenger-picker-triangle {
                position: absolute;
                bottom: -6px;
                margin-left: 10px;
                border: 10px solid transparent;
                border-top: 9px solid #fff;

                &::before {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 7px;
                    left: -11px;
                    border: 11px solid transparent;
                    border-top: 10px solid $color_grey_2;
                    z-index: -1;
                }
            }
        }
        .emojionearea-scroll-area {
            right: 1px;
            width: auto;
            height: 218px;
            padding-left: 12px;

            &::-webkit-scrollbar-track {
                background-color: #fff;
                border-radius: 4px;
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color_grey_3;
                border-radius: 4px;
            }
        }
        .emojionearea-wrapper {
            width: auto;

            &::after {
                display: none;
            }
        }
        .emojibtn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            padding: 0;
            margin: 0;
            background-color: #fff;
            border-radius: 100%;
            transition: background-color .1s ease-in-out;

            &:hover {
                background-color: $color_grey_2;
                border-radius: 100%;
            }
        }
        .emojionearea-filter {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            padding: 0;

            > img {
                margin: 0;
            }
        }
        .emojionearea-filters {
            height: auto;
            padding: 12px;
            background-color: #fff;
        }
        .emojionearea-category:first-of-type {
            margin-top: -10px;
        }
        .emojionearea-category-title {
            padding: 15px 8px 7px;
            font-size: 80%;
            font-weight: 400;
            color: $color_grey_3;
        }
        .emojionearea-category-block {
            padding: 0;
        }
    }
    .emojione,
    .emojioneemoji {
        width: 18px;
        min-width: auto;
        max-width: 18px;
        height: 18px;
        min-height: auto;
        max-height: 18px;
    }
}
.emojione,
.emojioneemoji {
    max-width: 16px;
    max-height: 16px;
    margin-top: -2px;
}

// Autocomplete dropdown
.textcomplete-dropdown {
    display: flex;
    visibility: visible;
    opacity: 1;
    // stylelint-disable-next-line
    z-index: 99993 !important;

    &.dropdown-menu[data-strategy="emojionearea"] {
        min-width: 10px;
        padding: 5px;
        border: 1px solid $color_grey_2;
        border-radius: .25rem;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .03);

        li.textcomplete-item {
            padding: 7px;
            font-size: 0;
            border-radius: 3px;

            img.emojioneemoji {
                margin-right: 0;
            }

            &.active,
            &:hover {
                background-color: $color_grey_2;
            }
        }
    }
}
